@tailwind base;
@tailwind components;
@tailwind utilities;

#app {
    background-image: url("./static/bg.jpg");
}

@layer components {
    .pc-bar {
        @apply m-2 w-[98%] h-10 rounded-full flex flex-row justify-between shadow-lg backdrop-blur-3xl bg-nord6/25;
    }

    .menu-item {
        @apply px-4 w-fit h-full rounded-full flex flex-row justify-center items-center gap-2 cursor-pointer hover:bg-sky-300;
    }

    .tag-item {
        @apply px-1.5 py-0.5 flex flex-row justify-center items-center rounded-lg border border-nord0;
    }

    .container {
        @apply min-w-[100vw] w-screen h-full shrink-0 flex justify-center items-center;
    }
}

.mobile-tab_bar {
    @apply w-full h-20 bg-nord4 flex items-center;
}

.mobile-tab-item {
    @apply flex flex-col justify-center items-center flex-1;
}

.mobile-tab-item > span {
    @apply text-center;
}

.tag-item > .material-icons-outlined {
    @apply text-xl mr-1;
}

.material-icons-outlined {
    @apply select-none;
}

.material-icons-outlined.md-5xl {
    @apply text-5xl;
}

.material-icons-outlined.md-3xl {
    @apply text-2xl;
}

@media (min-width: 640px) {
    .material-icons-outlined.md-3xl {
        @apply text-2xl;
    }
}